<template>
  <div>
    <div class="banner">
      <img src="@/assets/zp.jpg" alt="">
    </div>
    <div class="contain">
      <div class="contain_tit">
        <p>NOTICE</p>
        <h5>人才招聘</h5>
      </div>
      <div class="list">
        <div class="inner m_flex_box flex_vc" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
          <div class="date m_flex_box flex_vc">
            <div>
              <h5>{{ item.day }}</h5>
              <p>{{ item.ym }}</p>
            </div>
          </div>
          <div class="intro">
            <p class="text line_comp1">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="page_con">
				<el-pagination
					background
          @current-change="handleCurrentChange"
					layout="prev, pager, next"
          :page-size="pageRow"
					:total="count"
				>
				</el-pagination>
			</div>
  </div>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  name: 'News3',
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList();
  },
  beforeRouteLeave(to, from, next) {
    this.fullPath = from.fullPath;
    if(to.path == '/newsDetail') {
      this.$store.dispatch('saveView', ['News3', 'Home']).then(() => {
        next()
      })
    } else {
      this.$store.dispatch('saveView', ['Home']).then(() => {
        next()
      })
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({path: '/newsDetail', query: {id: id}})
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.getList();
      this.scrollToTop();
    },
    getList() {
      this.$http
      .post(
        "api.do?call&code=40289fd4864fba2301864fd31083000d",
        qs.stringify({
          order: " order by create_date desc",
          PAGE: this.pageNo,
          ROWS: this.pageRow,
          maxtype: '40289fd48ba8ded8018ba902235a0003',
          type: this.$route.query.type
        })
      )
      .then((res) => {
        this.list = res.result["40289fd4864fba2301864fd31083000d"].rows;
        this.count = res.result["40289fd4864fba2301864fd31083000d"].count
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .banner{
    img{
      width: 100%;
      @include Hei(652);
      object-fit: cover;
    }
  }
  .contain{
    width: 1310px;
    margin: 83px auto 20px;
    .contain_tit{
      p{
        font-size: 16px;
      }
      h5{
        font-size: 30px;
        font-weight: 400;
      }
    }
    .list{
      display: grid;
      grid-template-columns: repeat(2, 609px);
      grid-column-gap: 89px;
      .inner{
        cursor: pointer;
        padding: 20px 10px;
        border-bottom: 1px solid #dcdcdc;
        &:hover{
          transition: all 0.3s;
          border-color: #003399;
          .intro{
            transition: all 0.3s;
            color: #003399;
          }
        }
        .date{
          color: #003399;
          margin-right: 20px;
          h5{
            font-size: 30px;
            text-align: center;
          }
          p{
            font-size: 12px;
          }
          &:after{
            content: '/';
            display: block;
            margin-left: 20px;
          }
        }
        .intro{
          width: 85%;
          font-size: 16px;
        }
      }
    }
  }
  @media screen and(max-width: 1310px) {
    .contain{
      width: 100%;
      margin: 0 auto;
      @include Padding(0,20,0,20);
      @include MarT(80);
      @include MarB(20);
      .contain_tit{
        p{
          @include Size(16);
        }
        h5{
          @include Size(30);
        }
      }
      .list{
        display: grid;
        grid-template-columns: repeat(2, 46%);
        grid-column-gap: 4%;
        .inner{
          @include Padding(20,10,20,10);
          border-bottom: 1px solid #dcdcdc;
          .date{
            color: #003399;
            @include MarR(20);
            h5{
              @include Size(30);
            }
            p{
              @include Size(20);
            }
            &:after{
              @include MarL(20);
            }
          }
          .intro{
            width: 70%;
            @include Size(20);
          }
        }
      }
    }
  }
  @media screen and(max-width: 600px) {
    .contain{
      .contain_tit{
        p{
          @include Size(24);
        }
        h5{
          @include Size(36);
        }
      }
      .list{
        display: grid;
        grid-template-columns: repeat(1, 100%);
        grid-column-gap: 0%;
        .inner{
          .intro{
            width: 70%;
            @include Size(24);
          }
        }
      }
    }
  }
  .page_con{
    text-align: center;
    @include Margin(60,0,40,0);
  }
</style>