<template>
  <div>
    <div class="main_top">
			<h5 class="main_top_inner">{{ $route.query.name }}</h5>
		</div>
    <div class="contain">
      <div class="list">
        <div class="inner" v-for="item in list" :key="item.id">
          <div class="top">
            <el-image class="img" fit="contain" :src="item.img" :preview-src-list="[item.img]" alt="" />
            <div class="date">
							<h5>{{ item.date }}</h5>
							<p>{{ item.ym }}</p>
						</div>
            <div class="cover">
                <p class="line_comp4">{{ item.ms }}</p>
            </div>
          </div>
          <div class="intro">
            <h4 class="line_comp1">
								{{ item.title }}
							</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="page_con">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :page-size="pageRow"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleCurrentChange(e) {
      this.pageNo = e;
      this.getList();
      this.scrollToTop();
    },
    getList() {
      this.$http
      .post(
        "api.do?call&code=40289fd48bac8342018bacbd24070001",
        qs.stringify({
          order: " order by create_date desc",
          PAGE: this.pageNo,
          ROWS: this.pageRow,
        })
      )
      .then((res) => {
        this.list = res.result["40289fd48bac8342018bacbd24070001"].rows;
        this.count = res.result["40289fd48bac8342018bacbd24070001"].count
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.contain{
  min-height: 50vh;
  width: 1310px;
  margin: auto;
  @include MarT(87);
  .list{
    display: grid;
    grid-template-columns: repeat(3, 420px);
    grid-gap: 25px;
  }
  .inner{
    cursor: pointer;
    &:hover{
      box-shadow: 0 0 10px rgba(0,0,0,0.4);
      .img{
        transform: scale(1.1);
      }
      .cover{
        bottom: 0!important;
      }
    }
    .img{
      transition: all 0.3s;
      width: 100%;
      height: 263px;
      object-fit: cover;
    }
    .top{
      width: 100%;
      height: 263px;
      position: relative;
      overflow: hidden;
      background-color: rgba(0,0,0,0.1);
      .date{
        background-color: #003399;
        color: #fff;
        text-align: center;
          position: absolute;
          @include Padding(6,8,6,8);
          border-radius: 0 8px 0 0 ;
          left: 10px;
          bottom: 10px;
        h5{
          @include Size(18);
        }
        p{
          @include Size(12);
        }
      }
      .cover{
        transition: all 0.3s;
        position: absolute;
        bottom: -100%;
        left: 0;
        right: 0;
        @include Size(14);
        @include Padding(10,20,10,20);
        color: #fff;
        background-image: linear-gradient(to top, rgba(0,0,0,1) 40%, rgba(99,99,99,0.3) 100%);
      }
    }
    .intro{
      transition: all 0.3s;
      @include Padding(20,20,20,20);
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      border-radius: 0 0 8px 8px;
      text-align: center;
      h4{
        @include Size(16);
      }
      a{
        @include Size(14);
        color: #003399;
      }
    }
  }
}
.main_top{
  background-color: #003399;
  height: 133px;
  position: relative;
  .main_top_inner{
    width: 1310px;
    background-color: #F2F3F9;
    position: relative;
    top: 28px;
    margin: 0 auto;
    @include Size(30);
    @include LineH(128);
    &:before{
      content: '';
      display: block;
      background-color: #F2F3F9;
      height: 128px;
      position: absolute;
      width: 100%;
      right: 100%;
    }
  }
}
@media screen and(max-width: 1310px) {
  .contain{
    width: 100%;
    box-sizing: border-box;
    .list{
      display: grid;
      grid-template-columns: repeat(3, calc(100% / 3));
      grid-gap: 0;
    }
    .inner{
      padding: 6px;
      box-sizing: border-box;
      .top{
        @include Hei(263);
        .img{
          @include Hei(263);
        }
        .date{
          p{
            @include Size(16);
          }
        }
        .cover{
          @include Size(24);
        }
      }
        .intro{
        h4{
          @include Size(28);
          width: 100%;
        }
        a{
          @include Size(22);
          @include MarT(24);
        }
      }

    }
  }
    .main_top{
    background-color: #003399;
    height: 133px;
    position: relative;
    @include Hei(128);
    .main_top_inner{
      width: 80%;
      margin: 0;
      @include PadL(20);
    }
  }
}
@media screen and(max-width: 600px) {
  .contain{
    width: 100%;
    box-sizing: border-box;
    .list{
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-gap: 0;
    }
  }
    .main_top{
    background-color: #003399;
    height: 133px;
    position: relative;
    @include Hei(128);
    .main_top_inner{
      width: 80%;
      margin: 0;
      @include PadL(20);
    }
  }
}

.page_con{
  text-align: center;
  @include Margin(20,0,40,0);
}
</style>
