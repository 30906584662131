<template>
	<div>
		<div class="main_top">
			<h5 class="main_top_inner">{{ $route.query.name }}</h5>
		</div>
		<div class="contain">
			<div class="list">
				<div class="inner m_flex_box" v-for="item in list" :key="item.id" @click="toUrl(item.id)">
					<div class="left">
						<img
							:src="item.covermin"
							:alt="item.title"
						/>
						<div class="date">
							<h5>{{item.day}}</h5>
							<p>{{ item.ym }}</p>
						</div>
					</div>
					<div class="right flex-1">
						<div>
							<h4 class="line_comp2">
								{{ item.title }}
							</h4>
							<p class="line_comp2">{{ item.synopsis }}</p>
						</div>
						<span>查看详情>></span>
					</div>
				</div>
			</div>
			<div class="page_con">
				<el-pagination
					background
          @current-change="handleCurrentChange"
					layout="prev, pager, next"
          :page-size="pageRow"
					:total="count"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import qs from 'qs'
export default {
  name: 'News',
  data() {
    return {
      list: [],
      fullPath: ''
    }
  },
  mounted() {
  },
  watch: {
    $route: {
      handler(n) {
        if(n.path == '/news') {
          if(this.fullPath != n.fullPath) {
            this.pageNo = 1;
          }
          this.getList();
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.fullPath = from.fullPath;
    if(to.path == '/newsDetail') {
      this.$store.dispatch('saveView', ['News', 'Home']).then(() => {
        next()
      })
    } else {
      this.$store.dispatch('saveView', ['Home']).then(() => {
        next()
      })
    }
  },
  methods: {
    toUrl(id) {
      this.$router.push({path: '/newsDetail', query: {id}})
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.getList();
      this.scrollToTop();
    },
    getList() {
      this.$http
      .post(
        "api.do?call&code=40289fd4864fba2301864fd31083000d",
        qs.stringify({
          order: " order by create_date desc",
          PAGE: this.pageNo,
          ROWS: this.pageRow,
          type: this.$route.query.type,
          maxtype: this.$route.query.maxtype,
        })
      )
      .then((res) => {
        this.list = res.result["40289fd4864fba2301864fd31083000d"].rows;
        this.count = res.result["40289fd4864fba2301864fd31083000d"].count
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.contain{
  width: 1310px;
  margin: auto;
  @include MarT(87);
  min-height: 50vh;
  .inner{
    border-top: 1px solid #DCDCDC;
    @include Padding(30,0,30,0);
    cursor: pointer;
    img{
      @include Wid(270);
      @include Hei(170);
      object-fit: cover;
    }
    .left{
      @include Wid(270);
      @include Hei(170);
      @include MarR(38);
      position: relative;
      .date{
        background-color: #003399;
        color: #fff;
        text-align: center;
          position: absolute;
          @include Padding(6,8,6,8);
          border-radius: 0 8px 0 0 ;
          left: 0;
          bottom: 10px;
        h5{
          @include Size(18);
        }
        p{
          @include Size(12);
        }
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h4{
        @include Size(16);
        @include MarB(18);
      }
      p{
        @include Size(14);
        color: #999;
      }
      span{
        @include Size(14);
        color: #003399;
        text-align: right;
      }
    }
  }
}
.main_top{
  background-color: #003399;
  height: 133px;
  position: relative;
  .main_top_inner{
    width: 1310px;
    background-color: #F2F3F9;
    position: relative;
    top: 28px;
    margin: 0 auto;
    @include Size(30);
    @include LineH(128);
    &:before{
      content: '';
      display: block;
      background-color: #F2F3F9;
      height: 128px;
      position: absolute;
      width: 100%;
      right: 100%;
    }
  }
}
@media screen and(max-width: 1310px) {
  .contain{
    width: 100%;
    box-sizing: border-box;
    @include Padding(0,20,0,20);
    .inner{
      .left{
        .date{
          p{
          @include Size(16);
        }
        }
      }
        .right{
        h4{
          @include Size(28);
          width: 100%;
        }
        p{
          @include Size(24);
        }
        span{
          @include Size(22);
          @include MarT(24);
        }
      }

    }
  }
    .main_top{
    background-color: #003399;
    height: 133px;
    position: relative;
    @include Hei(128);
    .main_top_inner{
      width: 80%;
      margin: 0;
      @include PadL(20);
    }
  }
}
.page_con{
  text-align: center;
  @include Margin(20,0,40,0);
}
</style>
